import React, {useState, useEffect, useContext} from "react";
import ThreeYearHitterResults from "./ThreeYearHitterResults";
import ThreeYearHitterGraph from "./ThreeYearHitterGraph";
import CurrentSeasonHitterGraph from "./CurrentSeasonHitterGraph";
import CurrentSeasonHitterResults from "./CurrentSeasonHitterResults";
import { UserContext } from '../Context/UserContext';
import NotPremium from "./NotPremium";
import Logos from "./Logos";

function HitterSearch() {
    const {state} = useContext(UserContext);
    const [hitterNames, setHitterNames] = useState([])
    const [hitterData, setHitterData] = useState([])
    const [inputValue, setInputValue] = useState('');
    const [oldInputValue, setOldInputValue] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [ratingTimeframe, setRatingTimeframe] = useState(false)
    //console.log('timeFrame', ratingTimeframe)

    const subscription = state.user.subscription
    //console.log('sub', subscription)
    const hasSub = subscription && subscription.start_date && subscription.end_date ? true : false
    //console.log('hasSub?', hasSub)
    const today = new Date()
    const isActiveSub = hasSub && today >= new Date(subscription?.start_date) && today <= new Date(subscription?.end_date)
    //console.log('active sub?', isActiveSub)

    useEffect(() => {
        if (isActiveSub)
        fetch('/hitters')
            .then(res => res.json())
            .then(data => setHitterNames(data))
    }, [isActiveSub])

    function handleInputChange(e) {
        setInputValue(e.target.value)
    }

    useEffect(() => {
        if (inputValue === "" || inputValue === null || inputValue === undefined) {
            setSuggestions(hitterNames)
        }
        else {
            setSuggestions(hitterNames.filter(hitter => hitter.toLowerCase().includes(inputValue.toLowerCase())));
        }
    }, [inputValue]);

    function handleSearch(e) {
        e.preventDefault()
        const encodedName = encodeURIComponent(inputValue)
        fetch(`/hitters/${encodedName}`)
            .then(res => res.json())
            .then(data => {
                if (data) {
                    //console.log('hitterData', data)
                    setHitterData(data)
                }
                else {
                    alert("Error: Hitter not found")
                }
            })
        setOldInputValue(inputValue)
        setInputValue("")
    }

    function handleChange() {
        setRatingTimeframe(!ratingTimeframe)
    }

    if (isActiveSub)
    return (
        <div>
            <Logos />
            <div className="hitterTrends">
                <h2>MLB Hitter Trends</h2>
                <form onSubmit={handleSearch}>
                    <label>Search A Hitter: </label>
                    <input type="text" placeholder="Ex: Dansby Swanson" value={inputValue} onChange={handleInputChange}></input>
                    <button type="submit">Search</button>
                </form>
                {inputValue !== "" && (
                    <div className="searchSuggestions">
                        <ul>
                            {suggestions.map((suggestion, index) => (
                                <li className="searchLi" key={index} onClick={() => setInputValue(suggestion)}>
                                    {suggestion}
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
                {ratingTimeframe === false && hitterData?.last_10_results_three_year?.length > 0 && (
                    <>
                        <br />
                        <div className="ratings-dropdown">
                            <label>Select Rating Timeframe: </label>
                            <select onChange={handleChange} value={ratingTimeframe ? "Current Season Only" : "Default (Last 3 Seasons)"}>
                                <option>Default (Last 3 Seasons)</option>
                                <option>Current Season Only</option>
                            </select>
                        </div>
                        <ThreeYearHitterResults hitterData={hitterData} />
                        <ThreeYearHitterGraph hitterData={hitterData}/>
                    </>
                )}
                {ratingTimeframe === false && hitterData?.last_10_results_three_year?.length === 0 && (
                    <>
                        <br />
                        <div className="ratings-dropdown">
                            <label>Select Rating Timeframe: </label>
                            <select onChange={handleChange} value={ratingTimeframe ? "Current Season Only" : "Default (Last 3 Seasons)"}>
                                <option>Default (Last 3 Seasons)</option>
                                <option>Current Season Only</option>
                            </select>
                        </div>
                        <h2>No Default Elite Ratings for {oldInputValue}</h2>
                    </>
                )}
                {ratingTimeframe === true && hitterData?.last_10_results_current_season?.length > 0 && (
                    <>
                        <br />
                        <div className="ratings-dropdown">
                            <label>Select Rating Timeframe: </label>
                            <select onChange={handleChange} value={ratingTimeframe ? "Current Season Only" : "Default (Last 3 Seasons)"}>
                                <option>Default (Last 3 Seasons)</option>
                                <option>Current Season Only</option>
                            </select>
                        </div>
                        <CurrentSeasonHitterResults hitterData={hitterData} />
                        <CurrentSeasonHitterGraph hitterData={hitterData}/>
                    </>
                )}
                {ratingTimeframe === true && hitterData?.last_10_results_current_season?.length === 0 && (
                    <>
                        <br />
                        <div className="ratings-dropdown">
                            <label>Select Rating Timeframe: </label>
                            <select onChange={handleChange} value={ratingTimeframe ? "Current Season Only" : "Default (Last 3 Seasons)"}>
                                <option>Default (Last 3 Seasons)</option>
                                <option>Current Season Only</option>
                            </select>
                        </div>
                        <h2>No Current Season Elite Ratings for {oldInputValue}</h2>
                    </>
                )}
            </div>
        </div>
    )
    else {
        return (
            <div className="userNotPremium">
                <NotPremium />
            </div>
        )
    }
}

export default HitterSearch