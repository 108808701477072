import React, {useState, useContext, useEffect} from "react";
import { UserContext } from '../Context/UserContext';
import ThreeYearPitcherRatings from "./ThreeYearPitcherRatings";
import CurrentSeasonPitcherRatings from "./CurrentSeasonPitcherRatings";
import GameBar from "./GameBar";
import NotPremium from "./NotPremium";
import Logos from "./Logos";

function PitcherRatings() {
    const {state} = useContext(UserContext);
    const [ratingTimeframe, setRatingTimeframe] = useState(false)
    const [formattedDate, setFormattedDate] = useState("")
    //false is default (last 3 seasons), true is current season only
    //console.log('ratingTimeFrame', ratingTimeframe)
    //console.log('state', state)

    useEffect(() => {
        if (state?.games.length > 0) {
            const statCreatedDate = state?.games[0].created_at
            const [datePart] = statCreatedDate.split('T')
            const [year, month, day] = datePart.split('-')
            setFormattedDate(`${parseInt(month)}-${day}`)
        }
    }, [state.games])

    function handleChange() {
        setRatingTimeframe(!ratingTimeframe)
    }

    const subscription = state.user.subscription
    //console.log('sub', subscription)
    const hasSub = subscription && subscription.start_date && subscription.end_date ? true : false
    //console.log('hasSub?', hasSub)
    const today = new Date()
    const isActiveSub = hasSub && today >= new Date(subscription?.start_date) && today <= new Date(subscription?.end_date)
    //console.log('active sub?', isActiveSub)

    if (isActiveSub && ratingTimeframe)
    return (
        <div>
            <Logos />
            <div className="ratings-header">
                {formattedDate && (<h2 className="ratings-header">MLB Pitcher Ratings for {formattedDate}</h2>)}
            </div>
            <GameBar />
            <div className="ratings-dropdown">
                <label>Select Rating Timeframe: </label>
                <select onChange={handleChange}>
                    <option>Default (Last 3 Seasons)</option>
                    <option>Current Season Only</option>
                </select>
            </div>
            <CurrentSeasonPitcherRatings />
        </div>
    )
    else if (isActiveSub)
    return (
        <div>
            <Logos />
            <div className="ratings-header">
                {formattedDate && (<h2 className="ratings-header">MLB Pitcher Ratings for {formattedDate}</h2>)}
            </div>
            <GameBar />
            <div className="ratings-dropdown">
                <label>Select Rating Timeframe: </label>
                <select onChange={handleChange}>
                    <option>Default (Last 3 Seasons)</option>
                    <option>Current Season Only</option>
                </select>
            </div>
            <ThreeYearPitcherRatings />
        </div>
    )
    else {
        return (
            <div className="userNotPremium">
                <NotPremium />
            </div>
        )
    }
}

export default PitcherRatings